// ---------------------------------------------
// BOOTSTRAP OVERRIDES / EXTENSIONS
// ---------------------------------------------

// ---------------------------------------------
// STYLES
// ---------------------------------------------



// -----------------------------------------------------------------------------------------
// NEWS w/IMAGES
// -----------------------------------------------------------------------------------------

// not BEM compliant
.news .img {
    font-size: .750em;
    text-align: center;
    color: #666;
}



